import { Component, Input, OnDestroy, OnInit, Signal } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { PackageUser } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { ProductType } from 'src/app/features/packages/models';
import { AbstractWizardStepComponent, CheckInTaskCode, WizardStep } from 'src/app/features/wizard';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import {
  PrivacyPolicyConsentModalComponent,
  UseOfAIConsentModalComponent,
  TermsOfServiceDeclineModalComponent,
  TermsOfUseConsentModalComponent,
} from '../../components';
import { TermsAndConditionsConsent } from '../../models';
import { ConsentsActions, ConsentsSelectors, PresignConsentsActions } from '../../store';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
  providers: [
    {
      provide: AbstractWizardStepComponent,
      useExisting: TermsOfServiceComponent,
    },
  ],
})
export class TermsOfServiceComponent
  extends AbstractWizardStepComponent
  implements OnInit, OnDestroy
{
  @Input() user: PackageUser;
  @Input() isPresignComponent = false;

  privacyPolicyConsentCheckbox: UntypedFormControl;
  termsOfUseConsentCheckbox: UntypedFormControl;
  useOfAIConsentCheckbox: UntypedFormControl;

  isPrivacyPolicyConsentCheckboxEnabledSubscription: Subscription;
  isUseOfAIConsentCheckboxEnabledSubscription: Subscription;
  isTermsOfUseConsentCheckboxEnabledSubscription: Subscription;
  termsOfServiceAcceptedSubscription: Subscription;
  termsAndConditionsConsent$: Observable<TermsAndConditionsConsent>;

  submissionFailure: boolean;
  isRONOrKRON: Signal<boolean>;

  subHeadingText =
    'Your security is important to us. To make sure this transaction is secure and meets state regulations we will be saving video and audio information.';

  nonAvSubheadingText =
    'Your security is important to us. Please review and agree to our privacy policy and terms of use before checking the boxes below and continuing.';

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly router: Router
  ) {
    super(store);

    this.stepMetadata = {
      stepName: 'Your Terms of Service',
      checkInTaskCode: CheckInTaskCode.TermsOfService,
    } as WizardStep;

    this.isRONOrKRON = this.store.selectSignal(PackagesSelectors.isRONOrKRON)

    this.privacyPolicyConsentCheckbox = new UntypedFormControl(false, [Validators.requiredTrue]);
    this.termsOfUseConsentCheckbox = new UntypedFormControl(false, [Validators.requiredTrue]);

    this.formStep = new UntypedFormGroup({
      privacyPolicyConsentCheckbox: this.privacyPolicyConsentCheckbox,
      termsOfUseConsentCheckbox: this.termsOfUseConsentCheckbox,
    });

    if (this.isRONOrKRON()) {
      this.useOfAIConsentCheckbox = new UntypedFormControl(false, [Validators.requiredTrue]);
      this.formStep.addControl('useOfAIConsentCheckbox', this.useOfAIConsentCheckbox);
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.termsAndConditionsConsent$ = this.store.pipe(
      select(ConsentsSelectors.getTermsAndConditionsConsent)
    );

    this.isPrivacyPolicyConsentCheckboxEnabledSubscription = this.store
      .pipe(select(ConsentsSelectors.getIsPrivacyPolicyConsentCheckBoxEnabled))
      .pipe(
        tap((isConsentCheckBoxEnabled) => {
          this.privacyPolicyConsentCheckbox.setValue(isConsentCheckBoxEnabled);
          this.resetFormStepErrorMessage();
        })
      )
      .subscribe();

    this.isTermsOfUseConsentCheckboxEnabledSubscription = this.store
      .pipe(select(ConsentsSelectors.getIsTermsOfUseConsentCheckBoxEnabled))
      .pipe(
        tap((isConsentCheckBoxEnabled) => {
          this.termsOfUseConsentCheckbox.setValue(isConsentCheckBoxEnabled);
          this.resetFormStepErrorMessage();
        })
      )
      .subscribe();

    if (this.isRONOrKRON()){
      this.isUseOfAIConsentCheckboxEnabledSubscription = this.store.select(ConsentsSelectors.getIsUseOfAIConsentCheckBoxEnabled)
      .pipe(
        tap((isConsentCheckBoxEnabled) => {
          this.useOfAIConsentCheckbox.setValue(isConsentCheckBoxEnabled);
          this.resetFormStepErrorMessage();
        })
      )
      .subscribe();
    }


    if (this.isPresignComponent) {
      this.store.dispatch(PresignConsentsActions.FetchTermsAndConditionsConsent());
      this.store.dispatch(PresignConsentsActions.FetchPrivacyPolicyConsent());
      this.store.dispatch(PresignConsentsActions.FetchUseOfAIConsent());
    } else {
      this.store.dispatch(ConsentsActions.FetchTermsAndConditionsConsent());
      this.store.dispatch(ConsentsActions.FetchPrivacyPolicyConsent());
      this.store.dispatch(ConsentsActions.FetchUseOfAIConsent());
    }

    this.store
      .select(PackagesSelectors.getProductType)
      .pipe(
        take(1),
        filter(
          (productType) => productType === ProductType.Hybrid || productType === ProductType.IPEN
        ),
        tap(() => {
          this.subHeadingText = this.nonAvSubheadingText;
        })
      )
      .subscribe();

  
    this.termsOfServiceAcceptedSubscription = this.store
      .select(ConsentsSelectors.selectAreConsentsAccepted)
      .pipe(
        filter((areConsentsAccepted) => areConsentsAccepted),
        take(1),
        tap(() => super.goToNextStep())
      )
      .subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.isPrivacyPolicyConsentCheckboxEnabledSubscription?.unsubscribe();
    this.isTermsOfUseConsentCheckboxEnabledSubscription?.unsubscribe();
    this.isUseOfAIConsentCheckboxEnabledSubscription?.unsubscribe();
    this.termsOfServiceAcceptedSubscription?.unsubscribe();
  }

  togglePrivacyPolicyConsentCheckbox() {
    if (this.privacyPolicyConsentCheckbox.invalid) {
      this.store.dispatch(ConsentsActions.SetDisabledPrivacyPolicyConsentCheckBox());
    } else {
      this.store.dispatch(ConsentsActions.SetEnabledPrivacyPolicyConsentCheckBox());
    }
  }

  toggleTermsOfUseConsentCheckbox() {
    if (this.termsOfUseConsentCheckbox.invalid) {
      this.store.dispatch(ConsentsActions.SetDisabledTermsOfUseConsentCheckBox());
    } else {
      this.store.dispatch(ConsentsActions.SetEnabledTermsOfUseConsentCheckBox());
    }
  }

  toggleUseOfAIConsentCheckbox() {
    if (this.useOfAIConsentCheckbox.invalid) {
      this.store.dispatch(ConsentsActions.SetDisabledUseOfAIConsentCheckBox());
    } else {
      this.store.dispatch(ConsentsActions.SetEnabledUseOfAIConsentCheckBox());
    }
  }

  openPrivacyPolicyConsentModal(event: Event) {
    event.preventDefault();
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: PrivacyPolicyConsentModalComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

  openUseOfAIConsentModal(event: Event) {
    event.preventDefault();
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: UseOfAIConsentModalComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

  openTermsOfUseConsentModal(event: Event) {
    event.preventDefault();
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: TermsOfUseConsentModalComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

  public openDeclineModal(): void {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: TermsOfServiceDeclineModalComponent,
          modalSize: ModalSize.large,
          componentData: {
            isPresignComponent: this.isPresignComponent,
          },
        },
      })
    );
  }

  resetFormStepErrorMessage() {
    if (this.submissionFailure && this.formStep.valid) {
      this.submissionFailure = false;
    }
  }

  onStepVisible() {
    this.applicationInsightsService.logPageView(`${this.stepMetadata.stepName}`, this.router.url);
  }

  public validateStep() {
    this.submissionFailure = this.formStep.invalid;
  }

  public goToNextStep(): void {
    this.validateStep();
    if (this.formStep.valid) {
      if (this.isPresignComponent) {
        this.store.dispatch(PresignConsentsActions.AcceptTermsOfService());
      } else {
        this.store.dispatch(ConsentsActions.AcceptTermsOfService());
      }
    }
  }
}
