import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConsentsState } from '../state';
import { PackagesSelectors } from 'src/app/features/packages';

export const getConsentsState = createFeatureSelector<ConsentsState.State>('consents');

export const getPrivacyPolicyConsent = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.privacy
);

export const getUseOfAIConsent = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.useOfAI
);

export const getCommissionConsent = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.commission
);

export const getPrivacyPolicyConsentAccepted = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.privacy?.isAccepted ?? false
);

export const getUseOfAIConsentAccepted = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.useOfAI?.isAccepted ?? false
);

export const getTermsAndConditionsConsent = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.termsAndConditions ?? null
);

export const getKnownSignerConsent = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state?.knownSigner ?? null
);

export const getTermsAndConditionsConsentAccepted = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.termsAndConditions?.isAccepted ?? false
);

export const getEndorsementsConsent = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.endorsements ?? null
);

export const getEULAContent = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.eula
);

export const getEULAIsAccepted = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.eula?.isAccepted
);

export const getCommissionConsentTextId = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.commission.consentTextId
);

export const getIsPrivacyPolicyConsentCheckBoxEnabled = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.isPrivacyPolicyConsentCheckBoxEnabled
);

export const getIsUseOfAIConsentCheckBoxEnabled = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.isUseOfAIConsentCheckBoxEnabled
);

export const getIsTermsOfUseConsentCheckBoxEnabled = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.isTermsOfUseConsentCheckBoxEnabled
);

export const getIsSignatureConsentAgreementCheckboxEnabled = createSelector(
  getConsentsState,
  (state: ConsentsState.State) => state.isSignatureConsentAgreementCheckboxEnabled
);

export const selectIsUseOfAiAcceptedOrNotRequired = createSelector(
  PackagesSelectors.isRONOrKRON,
  getUseOfAIConsentAccepted,
  (isRONOrKRON, isUseOfAiAccepted) => isRONOrKRON ? isUseOfAiAccepted:  true
);

export const selectAreConsentsAccepted = createSelector(
  getPrivacyPolicyConsentAccepted,
  getTermsAndConditionsConsentAccepted,
  selectIsUseOfAiAcceptedOrNotRequired,
  (isPrivacyPolicyAccepted, isTermsOfUseAccepted, selectIsUseOfAiAcceptedOrNotRequired) =>
    isPrivacyPolicyAccepted && isTermsOfUseAccepted && selectIsUseOfAiAcceptedOrNotRequired
);
