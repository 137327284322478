//#region imports
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import { ModalsActions } from 'src/app/features/modals/store/actions';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { WizardSelectors } from 'src/app/features/wizard/store/selectors';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { ConsentsService } from '../../services';
import { ConsentsActions } from '../actions';
import { ConsentsSelectors } from '../selectors';

//#endregion imports

@Injectable()
export class ConsentsEffects {

  declineTermsOfService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.DeclineTermsOfService),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(ConsentsSelectors.getTermsAndConditionsConsent)),
            this.store.pipe(select(ConsentsSelectors.getPrivacyPolicyConsent)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(ConsentsSelectors.getUseOfAIConsent)),
          )
        )
      ),
      exhaustMap(
        ([
          _,
          packageUserGuid,
          termsAndConditionsConsent,
          privacyPolicyConsent,
          deviceCode,
          useOfAIConsent,
        ]) => {
          return this.consentsService
            .declineTermsOfService({
              termsOfUseConsentTextId: termsAndConditionsConsent.consentTextId,
              privacyPolicyConsentTextId: privacyPolicyConsent.consentTextId,
              packageUserGuid: packageUserGuid,
              deviceCode,
              aiDisclaimerConsentTextId: useOfAIConsent != null ? useOfAIConsent.consentTextId : 0,
            })
            .pipe(
              map(() => PackagesActions.OptOutPackage({ payload: { categoryTypeCode: "ParticipantConsentDecline", comments: "Participant declined terms of service" } })),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to accept terms of service.',
                      exceptionType: ExceptionType.CannotProceed,
                    },
                  })
                )
              )
            );
        }
      )
    )
  );

  getPrivacyPolicysConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchPrivacyPolicyConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.consentsService.getPrivacyConsent(packageUserGuid, deviceCode).pipe(
          map((payload) => ConsentsActions.SetPrivacyPolicyConsent({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get privacy policy.',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getUseOfAIConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchUseOfAIConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(WizardSelectors.getActiveWizardUserGuid),
            this.store.select(DeviceGroupSelectors.getDeviceCode),
            this.store.select(PackagesSelectors.isRONOrKRON)
          )
        )
      ),
      filter(([_, __, ___,isRONOrKRON]) => isRONOrKRON),
      switchMap(([_, packageUserGuid, deviceCode, __]) => {
        return this.consentsService.getUseOfAIConsent(packageUserGuid, deviceCode).pipe(
          map((payload) => ConsentsActions.SetUseOfAIConsent({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get use of ai.',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getTermsAndConditionsConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchTermsAndConditionsConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.consentsService
          .getTermsAndConditionsConsent(packageUserGuid, deviceCode)
          .pipe(
            map((payload) => ConsentsActions.SetTermsAndConditionsConsent({ payload })),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get terms and conditions.',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  getCommissionConsentByState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchCommissionsConsentByState),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))))
      ),
      switchMap(([action, deviceCode]) => {
        return this.consentsService.getCommissionConsentByState(action.payload, deviceCode).pipe(
          map((payload) => ConsentsActions.SetCommissionsConsentByState({ payload })),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get commissions consent by state.',
                },
              })
            )
          )
        );
      })
    )
  );

  acceptTermsOfService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.AcceptTermsOfService),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(ConsentsSelectors.getTermsAndConditionsConsent)),
            this.store.pipe(select(ConsentsSelectors.getPrivacyPolicyConsent)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(ConsentsSelectors.getUseOfAIConsent)),
          )
        )
      ),
      exhaustMap(
        ([
          _,
          packageUserGuid,
          termsAndConditionsConsent,
          privacyPolicyConsent,
          deviceCode,
          useOfAIConsent,
        ]) => {
          return this.consentsService
            .acceptTermsOfService({
              termsOfUseConsentTextId: termsAndConditionsConsent.consentTextId,
              privacyPolicyConsentTextId: privacyPolicyConsent.consentTextId,
              packageUserGuid: packageUserGuid,
              deviceCode,
              aiDisclaimerConsentTextId: useOfAIConsent != null ? useOfAIConsent.consentTextId : 0,
            })
            .pipe(
              map(() => ConsentsActions.AcceptTermsOfServiceSuccess()),
              catchError((err) =>
                of(
                  NotificationsActions.AddNotification({
                    payload: {
                      exception: err,
                      notificationType: NotificationType.Error,
                      id: uuid(),
                      text: 'Failed to accept terms of service.',
                      exceptionType: ExceptionType.CannotProceed,
                    },
                  })
                )
              )
            );
        }
      )
    )
  );

  consentToKnownSigner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.ConsentToKnownSigner),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(ConsentsSelectors.getKnownSignerConsent)),
            this.store.pipe(select(PackageUsersSelectors.getSigningAgent))
          )
        )
      ),
      exhaustMap(([action, deviceCode, knownSignerConsent, signingAgent]) => {
        return this.consentsService
          .consentToKnownSigner({
            consentTextId: knownSignerConsent.consentTextId,
            isAccepted: true,
            packageUserGuid: signingAgent.packageUserGuid,
            username: `${action.payload.firstName} ${action.payload.lastName}`,
            deviceCode,
          })
          .pipe(
            map(() => ConsentsActions.ConsentToKnownSignerSuccess()),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to consent to personal knowledge of signer.',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  getEndorsementConsents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchEndorsementsConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.consentsService
          .getEndorsementsConsent(packageUserGuid, deviceCode)
          .pipe(
            map((payload) => ConsentsActions.SetEndorsementsConsent({ payload })),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to get endorsement consent.',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  getEULAContentSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchEULAContent),
      map((_) => ModalsActions.ShowLoadingSpinner())
    )
  );

  getEULAContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchEULAContent),
      switchMap((_) => {
        return this.consentsService.getEulaContent().pipe(
          switchMap((payload) => [
            ConsentsActions.SetEULAContent({ payload }),
            ModalsActions.HideLoadingSpinner(),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get EULA.',
                },
              }),
              ModalsActions.HideLoadingSpinner()
            )
          )
        );
      })
    )
  );

  getKnownSignerConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.FetchKnownSignerConsent),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) => {
        return this.consentsService.getKnownSignerConsent(packageUserGuid, deviceCode).pipe(
          switchMap((payload) => [ConsentsActions.SetKnownSignerConsent({ payload })]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get known signer Consent.',
                },
              })
            )
          )
        );
      })
    )
  );

  acceptEULASpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.AcceptEULA),
      map((_) => ModalsActions.ShowLoadingSpinner())
    )
  );

  acceptEULA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsentsActions.AcceptEULA),
      switchMap((_) => {
        return this.consentsService.postEulaResponse({ isAccepted: true }).pipe(
          switchMap(() => [ConsentsActions.EULAAcceptedSuccessfully()]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get EULA.',
                  exceptionType: ExceptionType.Other,
                },
              }),
              ModalsActions.HideLoadingSpinner()
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly consentsService: ConsentsService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
