import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';

import { ModalsActions } from '../../../modals';
import { ConsentsActions, PresignConsentsActions } from '../../store';
import { PackagesSelectors } from 'src/app/features/packages';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-terms-of-service-decline-modal',
  templateUrl: './terms-of-service-decline-modal.component.html',
  styleUrls: ['./terms-of-service-decline-modal.component.scss'],
})
export class TermsOfServiceDeclineModalComponent implements OnDestroy {
  static identifier = 'TermsOfServiceDeclineModalComponent';
  @Input() isPresignComponent = false;
  isRonKron$: Observable<boolean>;

  constructor(private readonly store: Store<RootStoreState.State>) {
    this.isRonKron$ = this.store.select(PackagesSelectors.isRONOrKRON);
  }

  back() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  decline() {
    if (this.isPresignComponent) {
      this.store.dispatch(PresignConsentsActions.DeclineTermsOfService());
    } else {
      this.store.dispatch(ConsentsActions.DeclineTermsOfService());
    }
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: TermsOfServiceDeclineModalComponent },
      })
    );
  }
}
