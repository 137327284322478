<app-confirmation-modal>
  <ng-container subHeader>
    <p class="sub-header">Are you sure you want to decline?</p>
  </ng-container>

  <ng-container content>
    @if(isRonKron$ | async) {
      <p>
        Declining the Terms of Use, Use of AI and Privacy Policy will result in canceling your signing session.
      </p>
    }
    @else {
      <p>
        Declining the Terms of Use and Privacy Policy will result in canceling your signing session.
      </p>
    }
    <p>
      You will need to call support at <b><app-support-phone></app-support-phone></b> and reschedule
      your signing session.
    </p>
    <p>If you proceed we cannot guarantee the same mortgage rate and payments.</p>
  </ng-container>

  <ng-container actionButton>
    <app-button type="secondary" (click)="back()" [dataTestId]="'terms-of-service-decline-back'">
      Go Back
    </app-button>

    <app-button
      class="cancel-button"
      type="warning"
      (click)="decline()"
      [dataTestId]="'terms-of-service-decline-cancel'"
    >
      Decline and Cancel Signing
    </app-button>
  </ng-container>
</app-confirmation-modal>
