import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { RootStoreState } from 'src/app/store';

import { ModalsActions } from '../../../modals';
import { UseOfAIConsent } from '../../models';
import { ConsentsActions, ConsentsSelectors } from '../../store';

@Component({
  selector: 'app-use-of-ai-consent-modal',
  templateUrl: './use-of-ai-consent-modal.component.html',
  styleUrls: [],
})
export class UseOfAIConsentModalComponent implements OnInit, OnDestroy {
  static identifier = 'UseOfAIConsentModalComponent';
  useOfAIConsent$: Observable<UseOfAIConsent>;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.useOfAIConsent$ = this.store.pipe(select(ConsentsSelectors.getUseOfAIConsent));
  }

  back() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  accept() {
    this.store.dispatch(ConsentsActions.SetEnabledUseOfAIConsentCheckBox());
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }

  ngOnDestroy() {
    this.store.dispatch(
      ModalsActions.ClearModalComponentIfOpen({
        payload: { component: UseOfAIConsentModalComponent },
      })
    );
  }
}
