import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { delayedRetry } from 'src/app/operators';

import {
  EndorsementsConsent,
  PrivacyPolicyConsent,
  TermsAndConditionsConsent,
  TermsOfServiceRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PresignConsentsService {
  constructor(private readonly httpClient: HttpClient) {}

  acceptTermsOfService(request: TermsOfServiceRequest) {
    return this.httpClient.post(`presignConsents/accept/termsOfService`, request).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  declineTermsOfService(request: TermsOfServiceRequest) {
    return this.httpClient.post(`presignConsents/decline/termsOfService`, request).pipe(
      delayedRetry(500, 3),
      catchError((res) => {
        return throwError(res);
      })
    );
  }

  getPrivacyConsent(packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<PrivacyPolicyConsent>(
        `presignConsents/privacyPolicy/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getUseOfAIConsent(packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<PrivacyPolicyConsent>(
        `presignConsents/aiDisclaimer/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(() => res);
        })
      );
  }

  getTermsAndConditionsConsent(packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<TermsAndConditionsConsent>(
        `presignConsents/termsAndConditions/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(res);
        })
      );
  }

  getEndorsementsConsent(packageUserGuid: string, deviceCode: string) {
    return this.httpClient
      .get<EndorsementsConsent>(
        `presignConsents/endorsements/packageUsers/${packageUserGuid}/deviceCodes/${deviceCode}`
      )
      .pipe(
        delayedRetry(1000),
        catchError((res) => {
          return throwError(res);
        })
      );
  }
}
