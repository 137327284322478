import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProductType } from 'src/app/features/packages/models';
import { getProductType } from 'src/app/features/packages/store/selectors/packages.selectors';

import { AudioVideoConnectionMonitorState } from '../state';

export const getAudioVisualConnectionMonitorState = createFeatureSelector<AudioVideoConnectionMonitorState.State>(
  'audioVideoConnectionMonitor'
);

export const getSelectedAudioDevice = createSelector(
  getAudioVisualConnectionMonitorState,
  (state: AudioVideoConnectionMonitorState.State) => state.selectedAudioDevice
);

export const getSelectedAudioOutputDevice = createSelector(
  getAudioVisualConnectionMonitorState,
  (state: AudioVideoConnectionMonitorState.State) => state.selectedAudioOutputDevice
);

export const getSelectedVideoDevice = createSelector(
  getAudioVisualConnectionMonitorState,
  (state: AudioVideoConnectionMonitorState.State) => {
    return state.selectedVideoDevice;
  }
);

export const getIsOnAudioVideoConfigurationPage = createSelector(
  getAudioVisualConnectionMonitorState,
  (state: AudioVideoConnectionMonitorState.State) => {
    return state.isOnAudioVideoConfigurationPage;
  }
);

export const getIsGadgetGuideReady = createSelector(
  getAudioVisualConnectionMonitorState,
  (state: AudioVideoConnectionMonitorState.State) => {
    return state.isGadgetGuideReady;
  }
);

export const getCanDeviceSettingsModalBeDisplayed = createSelector(
  getIsOnAudioVideoConfigurationPage,
  getProductType,
  getIsGadgetGuideReady,
  (isOnAudioVideoConfigurationPage, product, isGadgetGuideReady) =>
    product !== ProductType.RemoteSigning && product !== ProductType.KRON
      ? false
      : isGadgetGuideReady && !isOnAudioVideoConfigurationPage
);
