import { createAction, props } from '@ngrx/store';

export const SetSelectedAudioVideoDevices = createAction(
  '[Audio Video Connection Monitor] Set Selected Audio Video Devices',
  props<{
    payload: {
      selectedAudioDevice: MediaDeviceInfo;
      selectedVideoDevice: MediaDeviceInfo;
      selectedAudioOutputDevice?: MediaDeviceInfo;
      shouldRefreshAfterSettingApplication?: boolean;
    };
  }>()
);

export const SetIsOnAudioVideoPage = createAction(
  '[Audio Video Connection Monitor] Set Is On Audio Video Page',
  props<{ payload: { isOnAudioVideoConfigurationPage: boolean } }>()
);

export const SetIsGadgetGuideReady = createAction(
  '[Audio Video Connection Monitor] Set Is Gadget Guide Ready',
  props<{ payload: { isGadgetGuideReady: boolean } }>()
);
