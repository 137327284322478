import { Action, createReducer, on } from '@ngrx/store';

import { ConsentsActions } from '../actions';
import { ConsentsState } from '../state';

const reducer = createReducer(
  ConsentsState.initialState,
  on(ConsentsActions.FetchPrivacyPolicyConsent, (state: ConsentsState.State) => ({
    ...state,
    privacy: ConsentsState.initialState.privacy,
    isPrivacyPolicyConsentCheckBoxEnabled: false,
  })),
  on(ConsentsActions.SetPrivacyPolicyConsent, (state: ConsentsState.State, { payload }) => ({
    ...state,
    privacy: payload,
  })),
  on(ConsentsActions.FetchUseOfAIConsent, (state: ConsentsState.State) => ({
    ...state,
    useOfAI: ConsentsState.initialState.useOfAI,
    isUseOfAIConsentCheckBoxEnabled: false,
  })),
  on(ConsentsActions.SetUseOfAIConsent, (state: ConsentsState.State, { payload }) => ({
    ...state,
    useOfAI: payload,
  })),
  on(ConsentsActions.FetchTermsAndConditionsConsent, (state: ConsentsState.State) => ({
    ...state,
    termsAndConditions: ConsentsState.initialState.termsAndConditions,
    isTermsOfUseConsentCheckBoxEnabled: false,
  })),
  on(ConsentsActions.SetTermsAndConditionsConsent, (state: ConsentsState.State, { payload }) => ({
    ...state,
    termsAndConditions: payload,
  })),
  on(ConsentsActions.SetKnownSignerConsent, (state: ConsentsState.State, { payload }) => ({
    ...state,
    knownSigner: payload,
  })),
  on(ConsentsActions.AcceptTermsOfServiceSuccess, (state: ConsentsState.State) => ({
    ...state,
    termsAndConditions: {
      ...state.termsAndConditions,
      isAccepted: true,
    },
    privacy: {
      ...state.privacy,
      isAccepted: true,
    },
    useOfAI: {
      ...state.useOfAI,
      isAccepted: true,
    },
  })),
  on(ConsentsActions.FetchEndorsementsConsent, (state: ConsentsState.State) => ({
    ...state,
    endorsements: ConsentsState.initialState.endorsements,
    isSignatureConsentAgreementCheckboxEnabled: false,
  })),
  on(ConsentsActions.SetEndorsementsConsent, (state: ConsentsState.State, { payload }) => ({
    ...state,
    endorsements: payload,
  })),
  on(ConsentsActions.SetEULAContent, (state: ConsentsState.State, { payload }) => ({
    ...state,
    eula: payload,
  })),
  on(ConsentsActions.EULAAcceptedSuccessfully, (state: ConsentsState.State) => ({
    ...state,
    eula: {
      ...state.eula,
      isAccepted: true,
    },
  })),
  on(ConsentsActions.FetchCommissionsConsentByState, (state: ConsentsState.State, action) => ({
    ...state,
    commission: ConsentsState.initialState.commission,
  })),
  on(ConsentsActions.SetCommissionsConsentByState, (state: ConsentsState.State, { payload }) => ({
    ...state,
    commission: payload,
  })),
  on(ConsentsActions.SetEnabledPrivacyPolicyConsentCheckBox, (state: ConsentsState.State) => ({
    ...state,
    isPrivacyPolicyConsentCheckBoxEnabled: true,
  })),
  on(ConsentsActions.SetDisabledPrivacyPolicyConsentCheckBox, (state: ConsentsState.State) => ({
    ...state,
    isPrivacyPolicyConsentCheckBoxEnabled: false,
  })),
  on(ConsentsActions.SetEnabledTermsOfUseConsentCheckBox, (state: ConsentsState.State) => ({
    ...state,
    isTermsOfUseConsentCheckBoxEnabled: true,
  })),
  on(ConsentsActions.SetDisabledUseOfAIConsentCheckBox, (state: ConsentsState.State) => ({
    ...state,
    isUseOfAIConsentCheckBoxEnabled: false,
  })),
  on(ConsentsActions.SetEnabledUseOfAIConsentCheckBox, (state: ConsentsState.State) => ({
    ...state,
    isUseOfAIConsentCheckBoxEnabled: true,
  })),
  on(ConsentsActions.SetDisabledTermsOfUseConsentCheckBox, (state: ConsentsState.State) => ({
    ...state,
    isTermsOfUseConsentCheckBoxEnabled: false,
  })),
  on(ConsentsActions.SetEnabledSignatureConsentAgreementCheckbox, (state: ConsentsState.State) => ({
    ...state,
    isSignatureConsentAgreementCheckboxEnabled: true,
  })),
  on(ConsentsActions.SetDisabledSignatureConsentAgreementCheckbox, (state: ConsentsState.State) => ({
    ...state,
    isSignatureConsentAgreementCheckboxEnabled: false,
  }))
);

export function consentsReducer(state: ConsentsState.State | undefined, action: Action) {
  return reducer(state, action);
}
