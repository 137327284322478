<app-confirmation-modal>
  <ng-container header>
    <h1>Use of AI</h1>
  </ng-container>

  <ng-container subHeader>
    <p>Here's what you need to know</p>
  </ng-container>

  <ng-container content>
    <app-scrollable-text-box [contentMarkup]="(useOfAIConsent$ | async)?.presentHtml | safeHtml">
    </app-scrollable-text-box>
  </ng-container>

  <ng-container actionButton>
    <app-button type="tertiary" onKeyDown="back()" (click)="back()" [dataTestId]="'use-of-ai-back'">
      Go Back
    </app-button>

    <app-button type="primary" onKeyDown="accept()"  (click)="accept()" [dataTestId]="'use-of-ai-agree'">
      Agree
    </app-button>
  </ng-container>
</app-confirmation-modal>
